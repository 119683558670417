body {
	font-family: 'Raleway', sans-serif;	
}
#root {
  max-width: 1200px;
  margin: 0 auto;
}
@media (min-width: 768px) {
	#root {
	  margin: 0 auto 0 270px;
	}	
}
button {
  border: none;
  background: none;	
}
.app-loading {
	padding: 200px 0;
	text-align: center;
}

*:focus {
  outline: none;
}

.slider-frame {
	padding-bottom: 3px !important;
}